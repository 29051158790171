import { eventBus } from "../event-bus";
import { AzureConfigModel, Model } from "./Model";
import { TConfig } from "global/x-mgr/x-mgr";
import { generateUID } from "global/utils";
import { alertController } from '@ionic/core';
import { Comm } from "./Comm";
import { BrowserCommExtra } from "./BrowserCommExtra";

export class ZService {
  commType: string = ''; //openai, openrouter, azureopenai

  name: string;
  givenName: string;
  apiURL: string; //base url for the service
  apiKey: string;
  description: string;

  isSecure: boolean; //is a secure service for corporate use.

  apiVersion: string; //version of the api - used for azure services

  useDefaultModels: boolean = true; //use the default models for the service
  models: Model[] = [];
  proxy: string = ''; //proxy for the service
  proxyKey: string = ''; //proxy key for the service
  commStyle: string = ''; //i.e. openai, azureopenai, direct, etc - used for custom

  useRemoteConfig: boolean = false; //use remote configuration for the service
  remoteConfigURL: string = ''; //url for the remote configuration

  _template?: ZServiceTemplate;
  authInfo?: { scope: string, auth: string, clientId: string, tenantId?: string };
  config?: TConfig;
  uid?: string;
  useLogin?: boolean = false;
  useApiKey?: boolean = true; //does the service use an api key (local/browser does not)
  aiTemplate?: string = 'openai';
  loadDefaultsLater?: boolean = false;
  proxyURL?: string;
  header1?: string;
  header2?: string;
  header3?: string;
  //serviceparams:02 (01 is allservices.json)

  constructor() {
    // generate a unique id for the service
    this.uid = 'S' + generateUID(8);
  }

  static debug(service: ZService, message: string) {
    return;
    // if (!service) {
    //   console.log('service is null: ' + message + (new Error()).stack);
    //   return;
    // }
    // console.log(service.models.length + ' : ' + (service.uid ?? 'x') + ' : ' + service.commType + ' : ' + message + ' : ' + (new Error()).stack);
  }

  static debugServices(services: ZService[], message: string) {
    // services.filter(s => s.commType === 'openai').map(s => {
    //   ZService.debug(s, message);
    // });
  }
}

export class ZCurrentModelInfo {
  id: string;
  service: string;
  owner: string;
}

export class ZModel {
  isVisible: boolean = true; //is the model visible in the UI - default true
  name: string; //model name
  description: string; //model description
  id: string; //model id
  maxTokens: number = 0; //max tokens for the model
  cost: number = 0; //per 1k tokens (output cost)
  type: string = ''; //text, image, tts (text to speech), stt (speech to text), embedding.
  isModerated: boolean = true; //is the model moderated
  payload: string = ''; //payload for the model
  replyPath: string = ''; //path to the reply

  azureInfo?: AzureConfigModel;
  isSelected?: boolean = false;
}

export class ZServiceTemplate {
  commName: string;
  apiURL: string;
  apiKey: string;
  commType: string;
  useDefaultModels: boolean;
  keyUrl?: string;

  static async getModelList() {
    var results = await fetch('https://openrouter.ai/api/v1/models')
      .then(response => response.json())
      .then(data => {
        return data;
      });

    return results;
  }

  static conform(models: any[], commType: string, service: ZService) {
    var newModels = [];
    models.forEach((model) => {
      var newModel = new ZModel();

      switch (commType) {
        case 'openai':
          newModel.maxTokens = model.max_tokens;
          newModel.cost = model.cost;
          newModel.type = model.type;
          newModel.isModerated = model.is_moderated;
          newModel.payload = model.payload;
          newModel.replyPath = model.reply_path;
          break;
      }

      newModel.name = model.name;
      newModel.description = model.description;
      newModel.id = model.id;
      newModel.maxTokens = model.maxTokens;
      newModel.cost = model.cost;
      newModel.type = model.type;
      newModel.isModerated = model.isModerated;
      newModel.payload = model.payload;
      newModel.replyPath = model.replyPath;
      newModels.push(newModel);
    });
  }
}

export class ZSettings {
  ui: UISettings;
  sys: SysSettings;
  currentModelInfo: ZCurrentModelInfo = null;
  services: ZService[];
  isDarkMode: boolean = true;
  globalInstructions: string = '';
  isLoading = false;
  loaded = false;
  needFirstTimeLoad = false;

  static instance: ZSettings = null;

  constructor() {
    this.services = [];
    this.default(!this.load());
    // if (!this.load()) {
    //   this.default();
    // }

    if (!ZSettings.instance) {
      ZSettings.instance = this;
    }

    eventBus.on('zsettings-loaded', () => {
      if (this.needFirstTimeLoad) {
        this.needFirstTimeLoad = false;
        window.setTimeout(() => {
          // THIS IS THE LINE THAT FAILS
          BrowserCommExtra.addDefaults(this);
        }, 1000);
      }
    });
  }

  static getInstance() {
    return ZSettings.instance === null ? new ZSettings() : ZSettings.instance;
  }

  // new-service: 03
  static getServiceTemplates(): ZServiceTemplate[] {
    const services = [
      { commName: 'OpenAI', apiURL: 'https://api.openai.com/v1', apiKey: '', commType: 'openai', useDefaultModels: true },
      { commName: 'AzureOpenAI', apiURL: 'https://api.openai.com/v1', apiKey: '', commType: 'azureopenai', useDefaultModels: true },
      { commName: 'OpenRouter', apiURL: 'https://openrouter.ai/api/v1', apiKey: '', commType: 'openrouter', useDefaultModels: true },
      { commName: 'groq', apiURL: 'https://api.groq.com/openai/v1', apiKey: '', commType: 'groq', useDefaultModels: true },
      { commName: 'huggingface', apiURL: 'https://api-inference.huggingface.co', apiKey: '', commType: 'huggingface', useDefaultModels: false },
      { commName: 'custom', apiURL: '', apiKey: '', commType: 'azureml', useDefaultModels: false },
      { commName: 'custom', apiURL: '', apiKey: '', commType: 'direct', useDefaultModels: false }

      //{ commName: 'copilot', apiURL: '', apiKey: '', commType: 'copilot', useDefaultModels: false }
    ];

    if ((window as any).ai) {
      services.push({ commName: 'browser', apiURL: '', apiKey: '', commType: 'browser', useDefaultModels: true });
    }

    return services;
  }

  static saveToFile(settingsJson: string, filename: string) {

    // Create a Blob from the JSON string
    const blob = new Blob([settingsJson], { type: 'application/json' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;

    // Append the anchor to the body (required for Firefox)
    document.body.appendChild(a);

    // Programmatically click the anchor to trigger the download
    a.click();

    // Remove the anchor from the document
    document.body.removeChild(a);

    // Revoke the object URL to free up memory
    URL.revokeObjectURL(url);
  }

  static loadFromFile(action: any) {
    // Create an input element to select a file
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/json';

    // Listen for file selection
    input.addEventListener('change', async (event) => {
      const file = (event.target as HTMLInputElement).files[0];
      if (file) {
        const text = await file.text();
        try {
          await action(text);
        } catch (error) {
          console.error('Failed to load file:', error);
        }
      }
    });

    // Programmatically click the input to open the file dialog
    input.click();
  }

  refreshSaveModels(models: Model[]) {
    //debugger;
    models.map(m => {
      if (m.zmodel) {
        m.zmodel.isSelected = m.isSelected;
      }
    });
  }

  getCurrentModel(models: Model[]): Model {
    if (this.currentModelInfo) {
      const model = models.find(m => m.id === this.currentModelInfo.id && m.comm?.name === this.currentModelInfo.service);
      if (model) {
        return model;
      }
    }

    return null;
  }

  hasAnyModels() {
    let hasModels = false;

    this.services.forEach((service) => {
      if (service.models.length > 0) {
        hasModels = true;
      }

      if (service.useDefaultModels && service.apiKey && service.apiKey.length > 0) {
        hasModels = true;
      }
    });

    return hasModels;
  }

  loadFromLegacy() {
    const openAIService = this.services.find(s => s.commType.toLowerCase().indexOf('openai') >= 0);
    if (!openAIService || !openAIService.apiKey || openAIService.apiKey.length === 0) {
      const openAIKey = localStorage.getItem('openai-key');
      if (openAIKey) {
        if (!localStorage.getItem('openai-key-legacy')) {
          if (openAIService) {
            openAIService.apiKey = openAIKey;
            this.save('services');
          }
          else {
            const openAi = new ZService();
            openAi.name = 'OpenAI';
            openAi.apiURL = 'https://api.openai.com/v1';
            openAi.apiKey = openAIKey;
            openAi.commType = 'openai';
            openAi.useDefaultModels = true;

            const openAITemplate = ZSettings.getServiceTemplates().find(t => t.commType === 'openai');
            this.addService(openAITemplate, openAi);
          }

          localStorage.setItem('openai-key-legacy', '1');
        }
      }
    }
  }

  firstTimeLoad() {
    // BrowserComm.addDefaults(this);
    this.needFirstTimeLoad = true;
  }

  firstTimeDefaults() {
    this.ui.advanced = 'basic';
  }

  load() {
    ZService.debug(null, 'ZSettings.load');
    //load settings from local storage, then later check each service for models
    const settings = localStorage.getItem('zsettings') ?? "{}";
    const isFirstTimeLoad = settings === '{}';

    var result = false;
    //debugger;
    if (settings) {
      if (!this.isLoading) {
        this.isLoading = true;
        try {
          result = this.loadFrom(settings);
        }
        catch (e) {
          console.error(e);
        }
        this.isLoading = false;
      }
    }

    this.loadFromLegacy();

    if (!this.loaded) {
      this.firstTimeLoad();
    }

    if (isFirstTimeLoad) {
      this.firstTimeDefaults();
    }
    else if (!this.ui?.advanced) {
      this.ui.advanced = 'advanced';
    }

    this.loaded = true;
    this.default(!this.loaded);

    return result;
  }

  loadFrom(settings) {
    if (settings) {
      const obj = JSON.parse(settings);
      if (obj.services) {
        this.services = obj.services;
        //ZService.debugServices(this.services, 'ZSettings.load');

        this.services.map(s => {
          if (!s.uid) {
            s.uid = 'S' + generateUID(8);
          }
          if (s.commType.toLowerCase().indexOf('azure') >= 0) {
            s.useDefaultModels = false;
          }
          if (s.commType.toLowerCase() === 'browser') {
            if (s.apiKey != 'window.ai') {
              // add model later
              s.loadDefaultsLater = true;
              // BrowserComm.sBrowserModels();
            }
            else {
              s.apiKey = 'window.ai';
            }
          }
        });
      }

      this.isDarkMode = typeof (obj.isDarkMode) === 'undefined' ? true : obj.isDarkMode;

      if (obj.ui) {
        this.ui = UISettings.fromJSON(obj.ui);
        if (!this.ui.subModelSettings) {
          this.ui.subModelSettings = {};
        }
      }
      else {
        this.ui = new UISettings();
      }

      if (obj.sys) {
        this.sys = SysSettings.fromJSON(obj.sys);
      }
      else {
        this.sys = new SysSettings();
      }

      if (obj.currentModelInfo) {
        this.currentModelInfo = obj.currentModelInfo;
      }

      //await eventBus.emit('zsettings-refresh', { key: "*", settings: this, isLoad: true });
      eventBus.emit('zsettings-loaded', { settings: this });
      return true;
    }
    else {
      this.ui = new UISettings();
      this.sys = new SysSettings();
      this.save();
    }

    return false;
  }

  clearAllStorage() {
    localStorage.clear();
    this.services = [];
    this.isDarkMode = true;
    this.globalInstructions = '';
    this.ui = new UISettings();
    this.sys = new SysSettings();
    this.currentModelInfo = null;
    this.load();
  }

  clear() {
    this.clearAllStorage();
  }

  save(key: string = null, uid: string = null) {
    // const getCircularReplacer = () => {
    //   const seen = new WeakSet();
    //   return (key, value) => {
    //     if (typeof value === "object" && value !== null) {
    //       if (seen.has(value)) {
    //         return;
    //       }
    //       seen.add(value);
    //     }
    //     return value;
    //   };
    // };

    //console.log('saving:'+key);

    //save settings to local storage
    //ZService.debugServices(this.services, 'zsettings:save1');
    const settings = JSON.stringify(this); //, getCircularReplacer()

    const reObj = JSON.parse(settings);
    if (reObj.services) {
      const len = reObj.services.length;
      for (var i = 0; i < len; i++) {
        const s = reObj.services[i];

        if (s.useDefaultModels) {
          s.models = s.models.filter(m => m.isSelected);
        }

        if (s.models) {
          s.models.map(m => {
            if (m.comm) {
              m.comm = { name: m.comm.name };
            }
            if (m.service) {
              m.service = { name: m.service.name, commType: m.service.commType, commName: m.service.commName };
            }
          });
        }

        if (key === 'services') {
          const comm = Comm.getCommForService(s);
          if (comm && comm.refreshFromService) {
            comm.refreshFromService(s);
          }
        }
      }
    }

    const reSettings = JSON.stringify(reObj);

    localStorage.setItem('zsettings', reSettings);
    eventBus.emit('zsettings-refresh', { key: key, settings: this, uid: uid });
  }

  addService(template: ZServiceTemplate, service: ZService) {
    //add a new service to the list
    service.commType = template.commType;
    service.apiURL = template.apiURL;
    service.apiKey = template.apiKey;
    service.useDefaultModels = template.useDefaultModels;
    service.models = [];
    this.services.push(service);
    this.save('services');
  }

  removeService(service: ZService) {
    //remove the service from the list
    const index = this.services.indexOf(service);
    if (index > -1) {
      this.services.splice(index, 1);
      this.save('services');
    }
  }

  private default(loadAll: boolean) {
    this.isDarkMode = true;

    if (loadAll || !this.services.find(s => s.commType === 'openai')) {
      const openAi = new ZService();
      openAi.name = 'OpenAI';
      openAi.apiURL = 'https://api.openai.com/v1';
      openAi.apiKey = '';
      openAi.commType = 'openai';
      openAi.useDefaultModels = true;
      this.services.push(openAi);
    }

    if (loadAll || !this.services.find(s => s.commType === 'openrouter')) {
      const openRouter = new ZService();
      openRouter.name = 'OpenRouter';
      openRouter.apiURL = 'https://openrouter.ai/api/v1';
      openRouter.apiKey = '';
      openRouter.commType = 'openrouter';
      openRouter.useDefaultModels = true;
      this.services.push(openRouter);
    }

    if (loadAll || !this.services.find(s => s.commType === 'groq')) {
      const groq = new ZService();
      groq.name = 'Groq';
      groq.apiURL = 'https://api.groq.com/openai/v1';
      groq.apiKey = '';
      groq.commType = 'groq';
      groq.useDefaultModels = true;
      this.services.push(groq);
    }

    if (loadAll || !this.services.find(s => s.commType === 'huggingface')) {
      const huggingface = new ZService();
      huggingface.name = 'HuggingFace';
      huggingface.apiURL = 'https://api-inference.huggingface.co';
      huggingface.apiKey = '';
      huggingface.commType = 'huggingface';
      huggingface.useDefaultModels = true;
      this.services.push(huggingface);
    }

    //;

    if (loadAll || !this.services.find(s => s.commType === 'browser')) {
      if ((window as any).ai) {
        const browser = new ZService();
        browser.name = 'window.ai';
        browser.apiURL = 'window.ai';
        browser.apiKey = '1';
        browser.commType = 'browser';
        browser.useDefaultModels = true;
        this.services.push(browser);
      }
    }
  }
}

export class SubModelSetting {
  id: string;
  uid?: string;
  level?: string; //(o3 -> high/medium/log)
}

export class UISettings {
  protectInstructions: boolean = true;
  msgFormat: string = 'markdown';
  wrap: string = 'wrap';
  showHistory: boolean = false;
  showSegs: boolean = false;
  showInstructions: boolean = true;
  advanced: string = 'basic';

  saveHistory: boolean = true; //HACK: consider per service
  openSegSettings: boolean = false;
  openSegInstructions: boolean = true;
  openSegSettingsInference: boolean = false;
  openSegSettingsUI: boolean = false;
  isSecureChat: boolean = false;
  wrapCode: boolean = true;
  sortModels: string = 'costdesc';
  subModelSettings: { [key: string]: SubModelSetting } = {};

  ask(message: string) {
    const result = confirm(message);
    return result;
  }

  async prompt(title: string, placeholder: string = ''): Promise<string | null> {
    return new Promise(async (resolve) => {
      const alert = await alertController.create({
        header: title,
        inputs: [
          {
            name: 'userInput',
            type: 'text',
            placeholder: placeholder
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel');
              resolve(null);
            }
          }, {
            text: 'Ok',
            handler: (data) => {
              console.log('User input:', data.userInput);
              resolve(data.userInput);
            }
          }
        ]
      })

      await alert.present();
    });
  }

  async copyText(title: string, text: string, quickName = 'Copy text'): Promise<string | null> {
    return new Promise(async (resolve) => {
      const alert = await alertController.create({
        header: title,
        inputs: [
          {
            name: 'userInput',
            type: 'text',
            value: text
          },
        ],
        buttons: [
          {
            text: 'Close',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              resolve(null);
            }
          }, {
            text: quickName,
            handler: (data) => {
              resolve(data.userInput);
            }
          }
        ]
      })

      await alert.present();
    });
  }

  toast(message: string) {
    eventBus.emit('ccx-toast', message);
  }

  static fromJSON(json: any): UISettings {
    let newUISettings = Object.create(UISettings.prototype);
    return Object.assign(newUISettings, json);
  }
}

export class SysSettings {
  autoSpeak: boolean = false;

  static fromJSON(json: any): SysSettings {
    let newSettings = Object.create(SysSettings.prototype);
    return Object.assign(newSettings, json);
  }
}
